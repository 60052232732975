import { ReactNode, useEffect, useState } from 'react'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { usePasswordSignUpMutation, useSignInWithPasswordMutation } from 'generated/graphql'
import * as z from 'zod'
import { ShadcnButton } from '../ui/button'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../ui/dialog'
import { SignInFormSchema, SignUpFormSchema } from './authFormSchema'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'

type AuthType = 'signUp' | 'signIn'
export interface AuthCardProps {
  children?: ReactNode
  defaultType?: AuthType
  forceOpen?: AuthType
  defaultEmail?: string
  onOpenChange?: (isOpen: boolean) => void
}

export const AuthCard = ({
  children,
  onOpenChange,
  forceOpen,
  defaultType = 'signIn',
  defaultEmail,
}: AuthCardProps) => {
  const [state, setState] = useState<AuthType>(defaultType)

  const [passwordSignUp, passwordSignUpData] = usePasswordSignUpMutation({ refetchQueries: 'all' })
  const [signIn, signInData] = useSignInWithPasswordMutation({ refetchQueries: 'all' })

  const onSubmit = async (values: z.infer<typeof SignUpFormSchema & typeof SignInFormSchema>) => {
    let shouldClose = false
    if (values.username && state === 'signUp') {
      const { email, password, username, firstName, lastName, isSubscribedToNewsletter } = values
      const res = await passwordSignUp({
        variables: {
          inputData: {
            email,
            password,
            id: username,
            firstName,
            lastName,
            isSubscribedToNewsletter,
          },
        },
      })
      shouldClose = !!res.data?.passwordSignUp
    } else if (state === 'signIn') {
      const { email, password } = values
      const res = await signIn({
        variables: { inputData: { idOrEmail: email, password } },
      })
      shouldClose = !!res.data?.signInWithPassword
    }
    if (shouldClose) {
      onOpenChange?.(false)
    }
  }

  useEffect(() => {
    if (forceOpen && forceOpen !== state) {
      setState(forceOpen)
    }
  }, [forceOpen, state])

  return (
    <Dialog
      key={
        passwordSignUpData.data?.passwordSignUp.User.id ??
        signInData.data?.signInWithPassword.User.id ??
        'init'
      }
      open={children ? undefined : !!forceOpen}
      onOpenChange={onOpenChange}
    >
      {children && <DialogTrigger asChild>{children}</DialogTrigger>}
      <DialogContent>
        <DialogHeader>
          <DialogTitle> {state === 'signIn' ? 'Sign in' : 'Sign up'}</DialogTitle>
        </DialogHeader>
        {state === 'signIn' ? (
          <SignIn onSubmit={onSubmit} />
        ) : (
          <SignUp defaultEmail={defaultEmail} onSubmit={onSubmit} />
        )}
        <DialogDescription className="text-center">
          {state === 'signIn' ? 'Do not have an account?' : 'Already have an account?'}
          <ShadcnButton
            variant="link"
            onClick={() => setState(state === 'signIn' ? 'signUp' : 'signIn')}
          >
            {state === 'signIn' ? 'Register' : 'Login'}
          </ShadcnButton>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}
