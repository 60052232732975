import { ReactNode, useState } from 'react'
import { motion, useMotionValueEvent, useScroll } from 'framer-motion'
import { Footer } from '../Footer'
import { Header } from '../Header'

export interface LayoutProps {
  hasFooter?: boolean
  children: ReactNode
}

export const Layout = ({ children, hasFooter = true }: LayoutProps) => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)

  const { scrollY } = useScroll()

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const previous = scrollY.getPrevious()
    if (latest > previous && latest > 150) {
      setIsHeaderVisible(false)
    } else {
      setIsHeaderVisible(true)
    }
  })

  return (
    <>
      <motion.header
        variants={{
          visible: { y: 0 },
          hidden: { y: '-100%' },
        }}
        animate={isHeaderVisible ? 'visible' : 'hidden'}
        transition={{ duration: 0.35, ease: 'easeInOut' }}
        className="fixed top-0 w-full z-sticky backdrop-blur-lg text-secondary-foreground"
      >
        <Header />
      </motion.header>
      <div className="rt-Box z-[-1] absolute top-0 left-0 right-0 bottom-0 w-[100vw]" />
      <div className="overflow-x-hidden">
        <main className="container relative mx-auto pt-24 pb-80 px-2 sm:px-3 md:px-4 lg:px-6 xl:px-8">
          {children}
        </main>
      </div>
      {hasFooter && (
        <footer className="absolute bottom-0 w-full h-72 bg-transparent text-secondary-foreground">
          <Footer className="container h-full" />
        </footer>
      )}
    </>
  )
}
