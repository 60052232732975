import React, { cloneElement } from 'react'
import { FaInstagram, FaPinterest, FaTiktok, FaYoutube } from 'react-icons/fa'
import { IconBaseProps } from 'react-icons/lib'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { ShadcnButton } from 'src/components/ui/button'
import { Text } from 'src/components/ui/Typography'

const SOCIAL_ICON_SIZE = 28

const footerSocialLinks = [
  {
    href: '/pinterest',
    icon: <FaPinterest />,
  },
  {
    href: '/ig',
    icon: <FaInstagram />,
  },
  {
    href: '/tt',
    icon: <FaTiktok />,
  },
  {
    href: '/yt',
    icon: <FaYoutube />,
  },
]

const footerNavLinks = [
  {
    href: '/about',
    text: 'About',
  },
  {
    href: '/contact',
    text: 'Contact',
  },
  {
    href: '/tos',
    text: 'Terms & Conditions',
  },
]

export interface FooterProps {
  className?: string
}

export const Footer = ({ className }: FooterProps) => (
  <div
    className={classNames(
      'sm:p-8 px-6 py-8 space-y-4 flex items-center flex-col justify-evenly',
      className
    )}
  >
    <div className="flex flex-col items-center w-full sm:flex-row gap-y-8 justify-evenly">
      <ul className="flex items-center justify-between flex-1 w-full gap-x-2">
        {footerSocialLinks.map(({ href, icon }) => (
          <li key={href}>
            <Link href={href}>
              {cloneElement(icon, {
                size: SOCIAL_ICON_SIZE,
                className: classNames(
                  'group-hover:scale-125 transition-all',
                  (icon.props as IconBaseProps).className
                ),
              })}
            </Link>
          </li>
        ))}
      </ul>
      <div className="flex flex-col items-center justify-center flex-1 text-center gap-y-4">
        <Link className="relative w-48 h-16" href="/">
          <Image src="/logo.png" fill style={{ objectFit: 'cover' }} alt="" />
        </Link>
      </div>
      <ul className="flex items-center justify-between flex-1 w-full px-2 sm:px-0 gap-x-2">
        {footerNavLinks.map(({ href, text }) => (
          <li key={href}>
            <ShadcnButton variant="link" asChild>
              <Link href={href}>{text}</Link>
            </ShadcnButton>
          </li>
        ))}
      </ul>
    </div>
    <Text className="block w-1/2 mx-auto text-center" size="sm">
      Your new favourite online cookbook.
    </Text>
  </div>
)
