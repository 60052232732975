import * as z from 'zod'

export const SignUpFormSchema = z.object({
  username: z.string().min(2).max(50).optional(),
  firstName: z.string().min(2).max(50).optional(),
  lastName: z.string().min(2).max(50).optional(),
  email: z.string().email(),
  password: z.string(),
  isSubscribedToNewsletter: z.boolean().optional(),
})

export const SignInFormSchema = z.object({
  email: z.string().email(),
  password: z.string(),
})
