import * as React from 'react'
import { BsPerson } from 'react-icons/bs'
import { RxHamburgerMenu } from 'react-icons/rx'
import Link from 'next/link'
import { headerLinks } from 'src/components/Header/headerLinks'
import { MenuSheet } from 'src/components/Header/MenuSheet'
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar'
import { ShadcnButton } from 'src/components/ui/button'
import { Text } from 'src/components/ui/Typography'
import { useAuth } from 'src/utils/auth'
import { HeaderLogo } from './HeaderLogo'

export const Header = () => {
  const {
    data: { User },
  } = useAuth()
  const [isMenuSheetOpen, setIsMenuSheetOpen] = React.useState(false)

  return (
    <>
      <div className="container py-1 px-2 sm:px-3 md:px-4 lg:px-6 xl:px-8">
        <nav className="flex items-center justify-between">
          <div className="lg:hidden w-12" />
          <HeaderLogo />
          <ul className="hidden lg:flex items-center gap-x-4 ">
            {headerLinks.map(({ href, text }) => (
              <li key={href}>
                <Link className="transition-colors hover:underline" href={href}>
                  <Text size="xl">{text}</Text>
                </Link>
              </li>
            ))}
          </ul>

          <div className="w-12 lg:w-48 flex justify-end">
            <ShadcnButton
              variant="ghost"
              className="relative"
              size="none"
              onClick={() => setIsMenuSheetOpen(true)}
            >
              <RxHamburgerMenu className="w-8 h-8 text-xl lg:hidden" />
              <Avatar className="hidden lg:block">
                {User.ImageS3File?.thumbnailSignedUrl && (
                  <AvatarImage src={User.ImageS3File?.thumbnailSignedUrl} alt={User.email ?? ''} />
                )}
                <AvatarFallback className="bg-gray-900">
                  <BsPerson className="text-white" size="1.5rem" />
                </AvatarFallback>
              </Avatar>
            </ShadcnButton>
          </div>
        </nav>
      </div>
      <MenuSheet isOpen={isMenuSheetOpen} onOpenChange={setIsMenuSheetOpen} />
      {/* <HeaderSearch isSearchOpen={isSearchOpen} /> */}
    </>
  )
}
