import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox } from 'src/components/ui/checkbox'
import * as z from 'zod'
import { ShadcnButton } from '../ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form'
import { ShadcnInput } from '../ui/input'
import { SignInFormSchema, SignUpFormSchema } from './authFormSchema'

export interface SignUpProps {
  defaultEmail?: string
  onSubmit: (values: z.infer<typeof SignUpFormSchema | typeof SignInFormSchema>) => void
}

export const SignUp = ({ defaultEmail, onSubmit }: SignUpProps) => {
  const form = useForm<z.infer<typeof SignUpFormSchema>>({
    defaultValues: {
      email: defaultEmail ?? '',
      isSubscribedToNewsletter: true,
    },
    resolver: zodResolver(SignUpFormSchema),
  })

  const [isSubscribedToNewsletter] = form.watch(['isSubscribedToNewsletter'])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex flex-col justify-between w-full gap-x-4 gap-y-8 md:flex-row">
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <ShadcnInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex-1" />
        </div>
        <div className="flex flex-col justify-between w-full gap-x-4 gap-y-8 md:flex-row">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <ShadcnInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <ShadcnInput {...field} type="password" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col justify-between w-full gap-x-4 gap-y-8 md:flex-row">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>First name</FormLabel>
                <FormControl>
                  <ShadcnInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Last name</FormLabel>
                <FormControl>
                  <ShadcnInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex items-end space-x-2">
          <Checkbox
            id="isSubscribedToNewsletter"
            checked={isSubscribedToNewsletter}
            onCheckedChange={(isChecked) => form.setValue('isSubscribedToNewsletter', !!isChecked)}
          />
          <label
            htmlFor="isSubscribedToNewsletter"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Subscribe to newsletter
          </label>
        </div>

        <ShadcnButton className="block w-full" type="submit">
          Sign up
        </ShadcnButton>
      </form>
    </Form>
  )
}
