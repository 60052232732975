import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { ShadcnButton } from '../ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form'
import { ShadcnInput } from '../ui/input'
import { SignInFormSchema, SignUpFormSchema } from './authFormSchema'

export interface SignInProps {
  onSubmit: (values: z.infer<typeof SignUpFormSchema | typeof SignInFormSchema>) => void
}

export const SignIn = ({ onSubmit }: SignInProps) => {
  const form = useForm<z.infer<typeof SignInFormSchema>>({
    resolver: zodResolver(SignInFormSchema),
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email or username</FormLabel>
              <FormControl>
                <ShadcnInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <ShadcnInput {...field} type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <ShadcnButton className="block w-full" type="submit">
          Login
        </ShadcnButton>
      </form>
    </Form>
  )
}
