import * as React from 'react'
import { cn } from 'src/components/utils/utils'

export interface ShadcnInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const ShadcnInput = React.forwardRef<HTMLInputElement, ShadcnInputProps>(
  ({ className, type, ...props }, ref) => (
    <input
      type={type}
      className={cn(
        'flex h-9 w-full rounded-md bg-input px-3 py-1 text-base shadow-sm transition-colors  file:border-0 file:bg-transparent file:text-base file:font-medium placeholder:text-input-foreground placeholder:opacity-60 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      ref={ref}
      {...props}
    />
  )
)
ShadcnInput.displayName = 'ShadcnInput'

export { ShadcnInput }
