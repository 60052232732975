import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import logo from './logo.png'

export const HeaderLogo = () => (
  <Link className="relative w-48 h-16" href="/">
    <Image src={logo} fill style={{ objectFit: 'cover' }} alt="" />
  </Link>
)
