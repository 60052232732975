import * as React from 'react'
import { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { AuthCard } from 'src/components/Auth/AuthCard'
import { headerLinks } from 'src/components/Header/headerLinks'
import { ShadcnButton } from 'src/components/ui/button'
import { Separator } from 'src/components/ui/separator'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from 'src/components/ui/sheet'
import { useAuth } from 'src/utils/auth'

interface Props {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}
export const MenuSheet = ({ isOpen, onOpenChange }: Props) => {
  const router = useRouter()
  const {
    signout,
    isLoggedIn,
    data: { User },
  } = useAuth()
  const [openAuthType, setOpenAuthType] = useState<'signUp' | 'signIn' | undefined>(undefined)

  React.useEffect(() => {
    if (openAuthType) {
      onOpenChange(false)
    }
  }, [openAuthType, onOpenChange])

  React.useEffect(() => {
    onOpenChange(false)
  }, [router.pathname])

  return (
    <>
      <Sheet open={isOpen} onOpenChange={onOpenChange}>
        <SheetContent size="sm" className="flex flex-col bg-white font-mono pt-2 px-0">
          <div className="flex flex-col gap-12">
            <div className="space-y-4 lg:hidden">
              <SheetHeader className="flex-none px-6">
                <SheetTitle className="">Menu</SheetTitle>
              </SheetHeader>
              <Separator />
              <div className="flex flex-col items-stretch space-y-1 [&>*]:justify-start px-2">
                {headerLinks.map(({ href, text }) => (
                  <ShadcnButton variant="ghost" asChild>
                    <Link href={href} className="">
                      {text}
                    </Link>
                  </ShadcnButton>
                ))}
              </div>
            </div>

            <div className="space-y-4">
              <SheetHeader className="flex-none px-6">
                <SheetTitle className="">Profile</SheetTitle>
                {isLoggedIn && (
                  <SheetDescription className="text-xs font-thin pt-0">
                    {User.email}
                  </SheetDescription>
                )}
              </SheetHeader>
              <Separator />
              <div className="flex flex-col items-stretch space-y-1 [&>*]:justify-start px-2">
                {isLoggedIn ? (
                  <>
                    <ShadcnButton variant="ghost" asChild>
                      <Link href="/settings/profile" className="">
                        Settings
                      </Link>
                    </ShadcnButton>
                    <ShadcnButton variant="ghost" asChild>
                      <Link href="/settings/bookmarks" className="">
                        Bookmarks
                      </Link>
                    </ShadcnButton>
                    <ShadcnButton variant="destructive" onClick={() => signout()}>
                      Log out
                    </ShadcnButton>
                  </>
                ) : (
                  <>
                    <ShadcnButton variant="ghost" onClick={() => setOpenAuthType('signIn')}>
                      Sign in
                    </ShadcnButton>
                    <ShadcnButton variant="ghost" onClick={() => setOpenAuthType('signUp')}>
                      Sign up
                    </ShadcnButton>
                  </>
                )}
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>
      <AuthCard
        forceOpen={openAuthType}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setOpenAuthType(undefined)
          }
        }}
      />
    </>
  )
}
